import React from 'react';
import ReactDOM from 'react-dom';
import TrackingSubscriber from './TrackingSubscriber';
import logFactory from 'c9-js-log-client';
import LoggerContext from './LoggerContext';

let context = window || {};

context.TrackingSubscriber = (function () {
    function boostrapApplication(config, boostrapElement) {
        let logger;
        logger = logFactory(`${config.apiEndpoints.logger}`, config.appName, config.logLevel);
        logger.debug(`Starting up application ${logger.appName}`);
        ReactDOM.render(
            <LoggerContext.Provider value={logger}>
                <TrackingSubscriber {...config} />
            </LoggerContext.Provider>,
            boostrapElement
        );
    }
    return boostrapApplication;
})();
