/**
 *
 * @param {Array|number} price
 * @returns {number}
 */
const getPrice = (price) => {
    if (Array.isArray(price) && price.length > 0 && typeof price[0] === 'number') {
        return price[0];
    }
    if (typeof price === 'number') return price;

    return 0;
};

export default getPrice;
